import styles from '../scss/Quote.module.scss';
import React from 'react';
import { IQuoteInternal } from '../utilities';

interface QuoteProps {
  quote: IQuoteInternal;
  colorMap: Map<string, string[]>;
  showAdminButtons: boolean;
  onEditClick?: (quote: IQuoteInternal) => void;
  onDeleteClick?: (quote: IQuoteInternal) => void;
}

function Quote({ quote, colorMap, showAdminButtons, onEditClick, onDeleteClick } : QuoteProps) {
  if (quote.data === undefined) {
    return (<div className={styles.quote}>
      ERREUR, cette citation ne devrais pas etre affichée.
   </div>);
  }

  return (
    <div className={styles.quote} style={{ '--background-gradient':
    `linear-gradient(270deg, ${(colorMap.get(quote.data.author) as any)[0]}, ${(colorMap.get(quote.data.author) as any)[1]})`,
    } as React.CSSProperties}>
      <div className={styles.text}>{quote.data.text}</div>
      <div className={styles.author_year}>- {quote.data.author}, {quote.data.year}</div>
      { showAdminButtons && (
        <>
          <div className={styles.adminButton}>
            <button onClick={() => { if (onEditClick !== undefined) onEditClick(quote); }}>Modifier</button>
          </div>
          <div className={styles.adminButton + ' ' + styles.deleteButton}>
            <button onClick={() => { if (onDeleteClick !== undefined) onDeleteClick(quote); }}>Supprimer</button>
          </div>
        </>
      ) }
    </div>
  );
}

export default Quote;
