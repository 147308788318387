import styles from '../scss/ScrollToTop.module.scss';
import React, { useEffect, useState } from 'react';
import { FaArrowUp } from 'react-icons/fa';

const scrollToTop = () => {
  const y = document.documentElement.getBoundingClientRect().top + window.scrollY;
  window.scroll({
    top: y,
    behavior: 'smooth',
  });
};

const GO_UP_BUTTON_OFFSET = 800; // Number of pixels to scroll before the "scroll to top" button appear

function ScrollToTop() {
  const [isShown, setIsShown] = useState<boolean>(true);

  const onScrollHandler = () => {
    if (window.pageYOffset > GO_UP_BUTTON_OFFSET) {
      setIsShown(false);
    } else {
      setIsShown(true);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', onScrollHandler);

    return () => {
      window.removeEventListener('scroll', onScrollHandler);
    };
  }, []);

  return (
    <button title='Retourner en haut de la page' className={`${styles.button} ${isShown === true ? '' : styles.hidden}`} onClick={scrollToTop}>
      <FaArrowUp size={20}/>
    </button>
  );
}

export default ScrollToTop;
