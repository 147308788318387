import styles from '../scss/TextInput.module.scss';
import React, { ChangeEventHandler, useEffect, useRef } from 'react';

interface TextInputProps { 
  label: string;
  id: string;
  type?: string;
  onChange?: ChangeEventHandler<HTMLInputElement>;
  placeholder?: string;
  dataList?: string[];
  disabled?: boolean;
  autofocus?: boolean;
  defaultValue?: string;
}

function TextInput({ label, id, type, onChange, placeholder, dataList, disabled = false, autofocus = false, defaultValue } : TextInputProps) {
  const inputRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    if (defaultValue === undefined || inputRef === null || inputRef.current == null) return;

    inputRef.current.value = defaultValue;
  }, []);

  return (
    <div className={styles.container}>
      <input ref={inputRef} autoFocus={autofocus} disabled={disabled} className={styles.input} type={type ?? 'text'} id={id}
      name={`name_${id}`} placeholder={placeholder} onChange={onChange} list={`list_${label}`}/>
      <label className={styles.label} htmlFor={`name_${id}`}>{label}</label>
      { dataList && 
        <datalist id={`list_${label}`}>
        { dataList.map((val) => (
          <option key={val} value={val}></option>
        ))}
        </datalist>
      }
    </div>
  );
}

export default TextInput;
