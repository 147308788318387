import styles from '../scss/CheckBox.module.scss';
import React, { ChangeEventHandler, useEffect } from 'react';

interface CheckBoxProps { 
  label: string;
  id: string;
  onChange?: ChangeEventHandler<HTMLInputElement>;
  disabled?: boolean;
  defaultValue?: boolean;
}

function CheckBox({ label, id, onChange, disabled = false, defaultValue } : CheckBoxProps) {
  useEffect(() => {
    if (defaultValue !== undefined)
      (document.getElementById(id) as HTMLInputElement).checked = defaultValue;
  }, [defaultValue, id]);

  return (
  <div className={styles.container}>
    <label className={`${styles.checkbox} ${styles.bounce}`}>
      <input type="checkbox" id={id} onChange={onChange} disabled={disabled} name={`name_${id}`}/>
      <svg viewBox="0 0 21 21">
        <polyline points="5 10.75 8.5 14.25 16 6"></polyline>
      </svg>
      <div className={styles.text}>{label}</div>
    </label>
  </div>
  );
}

export default CheckBox;
