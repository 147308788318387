import joi from 'joi';
import { NOTIFICATION_TYPE, Store } from 'react-notifications-component';
import { colord, extend } from 'colord';
import lchPlugin from 'colord/plugins/lch';
import { sha256 } from './sha.js';
import { ILoginJWTPayload, IRegisterJWTPayload } from '@shared/definitions.js';
import Joi from 'joi';

extend([lchPlugin]);

export interface IQuoteInternal {
  id: string;
  data?: {
    text: string;
    author: string;
    year: number;
    hashedMail: string;
  }
}

export interface ILoginInfo {
  tokenJWT: string;
  payloadJWT: ILoginJWTPayload;
  hashedMail: string;
}

export interface IRegisterInfo {
  tokenJWT: string;
  payloadJWT: IRegisterJWTPayload;
}

//export const ENV: string = 'dev';
export const ENV: string = 'prod';

export const sha256Hash = (dataToHash: string): string => {
  return sha256(dataToHash);
};

export const addNotif = (title: string, message: string, type: NOTIFICATION_TYPE) => {
  Store.addNotification({
    title, message, type,
    insert: 'top',
    container: 'top-left',
    animationIn: ['animate__animated', 'animate__fadeIn'],
    animationOut: ['animate__animated', 'animate__fadeOut'],
    dismiss: {
      duration: 5000,
      onScreen: true,
    },
  });
};

// https://stackoverflow.com/a/38552302/11191373
export const parseJWT = (token: string): any => {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function (c) {
    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
  }).join(''));

  return JSON.parse(jsonPayload);
};

export const parseJWTLogin = (token: string): ILoginJWTPayload => {
  const JWTLoginValidator = Joi.object({
    exp: Joi.number().required(),
    iat: Joi.number().required(),
    usr: Joi.string().required(),
  });

  const JWTLoginToken = parseJWT(token);
  const validation = JWTLoginValidator.validate(JWTLoginToken);

  if (validation.error === undefined) {
    return JWTLoginToken;
  } else {
    throw new Error(`Invalid login token format "${validation.error}"`);
  }
};

export const parseJWTRegister = (token: string): IRegisterJWTPayload => {
  const JWTRegisterValidator = Joi.object({
    exp: Joi.number().required(),
    iat: Joi.number().required(),
    per: Joi.string().required(),
    for: Joi.string().required(),
  });

  const JWTRegisterToken = parseJWT(token);
  const validation = JWTRegisterValidator.validate(JWTRegisterToken);

  if (validation.error === undefined) {
    return JWTRegisterToken;
  } else {
    throw new Error(`Invalid login token format "${validation.error}"`);
  }
};

// source: github copilot
export const randomIntBetween = (min: number, max: number) => {
  return Math.floor(Math.random() * (max - min + 1) + min);
};

// https://www.geeksforgeeks.org/how-to-create-hash-from-string-in-javascript/
// Convert to 32bit integer
export const stringToHash = (string: string): number => {
  let hash = 0;

  if (string.length === 0) return hash;
	
  for (let i = 0; i < string.length; i++) {
    const char = string.charCodeAt(i);
    hash = ((hash << 5) - hash) + char;
    hash = hash & hash;
  }
	
  return hash;
};

export const generateColorsFromHash = (hash: number): string[] => {
  const hue = hash % 360; // 0 -> 360
  const chroma = 60; // 0 -> approximately 131
  const lightness = 70; // 0 -> 100

  const a = colord({ l: lightness, c: chroma, h: hue }).toHex();
  const b = colord({ l: lightness, c: chroma, h: (hue + 135) % 360 }).toHex();
  return [a, b];
};

export const escapeHTML = (str: string) => {
  return new Option(str).innerHTML;
};

export const validateEmail = (email: string) => {
  const isEmailValid = joi.string().email({ tlds: { allow: false } }).required().validate(email).error === undefined;
  return isEmailValid;
};

export const getTimestamp = (): number => { // in seconds
  return Math.floor(+new Date() / 1000);
};
