import React from 'react';
import ReactDOM from 'react-dom/client';
import './scss/global.scss';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { ENV } from './utilities';
import { ReactNotifications } from 'react-notifications-component';
import App from './pages/App';
import Register from './pages/Register';
import Modal from 'react-modal';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

Modal.setAppElement('#root');

root.render(
  <React.StrictMode>
    {ENV === 'dev' && <div className="dev_banner">
      CURRENTLY IN DEV MODE
    </div>}
    <ReactNotifications />
    <BrowserRouter>
      <Routes>
        <Route path="/register/*" element={<Register/>} />
        <Route path="*" element={<App />} />
      </Routes>
    </BrowserRouter>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
