import { IQuote, IQuoteMeta, IUser } from '@shared/definitions';
import { addNotif, ENV, IQuoteInternal } from './utilities';

export const API_URL = 'https://sarcophage.gateofbabylon.net/api';

export let fetchQuotesId: () => Promise<string[]>;
export let fetchQuote: (id: string) => Promise<IQuoteInternal>;
export let addQuoteToDB: (authorizationToken: string, quote: IQuote) => Promise<any>;
export let editQuoteDB: (authorizationToken: string, quoteId: string, quote: IQuote) => Promise<any>;
export let deleteQuoteDB: (authorizationToken: string, quoteId: string) => Promise<any>;
export let loginToDB: ({ email, password } : IUser) => Promise<any>;
export let inviteFriendToDB: (authorizationToken: string, email: string) => Promise<any>;
export let confirmRegisterToDB: (registerToken: string, password: string) => Promise<any>;

export class ConnexionError extends Error {}
export class FetchError extends Error {
  constructor(response: Response) {
    super(`Received http code [${response.status}] ${response.statusText} when sending a query to "${response.url}"`);
    this.response = response;
  }
  response: Response;
}

const handleFetchError = (response: Response): Response => {
  if (!response.ok) throw new FetchError(response);
  return response;
};

export const defaultCatchFetchError = (error: Error) => {
  if (!(error instanceof FetchError)) throw error;
  
  addNotif(`Erreur lors de la communication avec ${error.response.url}`, `[${error.response.status}] ${error.response.statusText}`, 'danger');
};

// Dev mode is incomplete and has not been tested
switch (ENV) {
  case 'prod':
    fetchQuotesId = () => fetch(`${API_URL}/quotes`)
      .then(handleFetchError)
      .then(response => response.json());
     
    fetchQuote = (id: string) => fetch(`${API_URL}/quotes/${id}`)
      .then(handleFetchError)
      .then(response => response.json())
      .then((jsonData: { quote:IQuote, metadata: IQuoteMeta }) => {
        // Convert API response to internal interface
        return {
          id,
          data: {
            ...jsonData.quote,
            hashedMail: jsonData.metadata.hashed_email,
          },
        }; 
      });
 
    addQuoteToDB = (authorizationToken: string, quote: IQuote) => fetch(`${API_URL}/quotes`, {
      method: 'post',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${authorizationToken}`,
      },
      body: JSON.stringify(quote),
    })
      .then(handleFetchError)
      .then(response => response.json());
    
    editQuoteDB = (authorizationToken: string, quoteId: string, quote: IQuote) =>
      fetch(`${API_URL}/quotes/${quoteId}`, {
        method: 'put',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${authorizationToken}`,
        },
        body: JSON.stringify(quote),
      })
        .then(handleFetchError)
        .then(response => response.json());
    
    deleteQuoteDB = (authorizationToken: string, quoteId: string) =>
      fetch(`${API_URL}/quotes/${quoteId}`, {
        method: 'delete',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${authorizationToken}`,
        },
      });
       
    loginToDB = (user : IUser) => fetch(`${API_URL}/users/login`, {
      method: 'post',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(user),
    })
      .then(response => {
        if (response.status === 401) {
          throw new ConnexionError('Email ou mot de passe incorrect');
        }
             
        return response;
      })
      .then(handleFetchError)
      .then(response => response.json());
 
    inviteFriendToDB = (authorizationToken: string, email: string) => fetch(`${API_URL}/users/invite/${email}`, {
      method: 'post',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${authorizationToken}`,
      },
    })
      .then(handleFetchError);
 
    confirmRegisterToDB = (registerToken: string, password: string) => fetch(`${API_URL}/users/register/${registerToken}`, {
      method: 'post',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ password }),
    })
      .then(handleFetchError)
      .then((response) => response.json());
    break;
  default:
    if (ENV !== 'dev') console.error('No environnement provided. Fallback to dev mode');
 
    const DUMMY_API_DELAY_MS = 150;
    const DUMMY_DB = [
      { text: 'Dans leur clair ça devait être tellement tête', author: 'Maxim', year: 2022 },
      { text: 'Qui devez appelez vous ?', author: 'Joris', year: 2022 },
      { text: "Si t'as deux doigts, t'as un bras par main", author: 'Joris', year: 2022 },
      { text: 'Dans les pays asiatriques', author: 'Rui', year: 2022 },
      { text: 'Il va falloir que vous avez besoin', author: 'Raileanu', year: 2022 },
      { text: "Ce qui est à l'intérieur est aussi à l'intérieur", author: 'Leandro', year: 2022 },
      { text: "C'est tout ce que je peux à quoi penser", author: 'Joris', year: 2022 },
    ];
 
    fetchQuotesId = () => new Promise<string[]>((resolve) => {
      setTimeout(() => {
        const ids = DUMMY_DB.map((quote, index) => index.toString());
        resolve(ids);
      }, DUMMY_API_DELAY_MS);
    });
 
    fetchQuote = (id: string) => new Promise<IQuoteInternal>((resolve) => {
      setTimeout(() => {
        resolve({ id, data: { ...DUMMY_DB[parseInt(id)], hashedMail: 'sarcophage@gateofbabylon.net' } });
      }, DUMMY_API_DELAY_MS);
    });
 
    addQuoteToDB = (authorizationToken: string, quote: IQuote) => {
      DUMMY_DB.push(quote);
      return new Promise<IQuote>((resolve) => {
        setTimeout(() => {
          resolve(quote);
        }, DUMMY_API_DELAY_MS);
      });
    };
    
    editQuoteDB = (authorizationToken: string, quoteId: string, quote: IQuote) => {
      DUMMY_DB.push(quote);

      return new Promise<IQuote>((resolve) => {
        setTimeout(() => {
          resolve(quote);
        }, DUMMY_API_DELAY_MS);
      });
    };

    deleteQuoteDB = (authorizationToken: string, quoteId: string) => {
      DUMMY_DB[parseInt(quoteId)] = {
        text: 'DUMMY DELETED QUOTE',
        author: 'DUMMY DELETED QUOTE',
        year: 0,
      };

      return new Promise<void>((resolve) => {
        setTimeout(() => {
          resolve();
        }, DUMMY_API_DELAY_MS);
      });
    };
 
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    loginToDB = (user : IUser) => new Promise<any>(resolve => {
      setTimeout(() => {
        // return dummy token always valid
        // eslint-disable-next-line max-len
        resolve({ login_token: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c3IiOiJkZXZVc2VyIiwiZXhwIjozMDAwMDAwMDAwLCJpYXQiOjE2MDAwMDAwMDB9.W_90L_WnTSc68-v84lgPZbgPws61NREl8lZZTQvm1mc' });
      }, DUMMY_API_DELAY_MS);
    });
     
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    inviteFriendToDB = (authorizationToken: string, email: string) => new Promise<any>(resolve => {
      setTimeout(() => {
        resolve({ });
      }, DUMMY_API_DELAY_MS);
    });
 
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    confirmRegisterToDB = (registerToken: string, password: string) => new Promise<any>(resolve => {
      setTimeout(() => {
        resolve({ });
      }, DUMMY_API_DELAY_MS);
    });
}
 