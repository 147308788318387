import styles from '../scss/App.module.scss';
import closeIcon from '../icon/close.svg';
import braceTop from '../icon/braces_top.svg';
import braceBottom from '../icon/braces_bottom.svg';
import React, { ReactNode, useEffect, useRef, useState } from 'react';
import randSeed from 'random-seed';
import {
  stringToHash, generateColorsFromHash, randomIntBetween,
  addNotif, validateEmail, IQuoteInternal, ILoginInfo, sha256Hash, getTimestamp, parseJWTLogin,
} from '../utilities';
import {
  defaultCatchFetchError, ConnexionError,
  inviteFriendToDB, fetchQuotesId, fetchQuote, addQuoteToDB, loginToDB, editQuoteDB, deleteQuoteDB, FetchError,
} from '../DB';
import { IQuote, IUser } from '@shared/definitions';
import { Button, TextInput, TextArea, CheckBox, ScrollToTop, Header, Quote, QuoteOfTheDay } from '../components';
import 'react-notifications-component/dist/theme.css';
import 'animate.css/animate.min.css';
import Modal from 'react-modal';

const LOCAL_STORAGE_LOGIN = 'loginTokenJWT';
const KNOWN_AUTHORS = ['Leandro', 'Rui', 'Joris', 'Maxim', 'Yan', 'Théo', 'William', 'Benoit', 'Alexandre', 'Alice', 'Amélie'];
const PAGE_DOWN_OFFSET = 100; // Number of pixels where it already consider us at the bottom of the page
const NO_QUOTES_MESSAGES = ['Sortez un peu dehors.', 'Trouvez-vous un hobby.'];

function App() {
  const [quotes, setQuotes] = useState<IQuoteInternal[]>([]);
  const [quoteOfTheDay, setQuoteOfTheDay] = useState<IQuoteInternal>({ id: 'lol', data: undefined });
  const [loginInfo, setLoginInfo] = useState<ILoginInfo | null>(null);
  const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);
  const [modalContent, setModalContent] = useState<ReactNode>(<></>);
  const colorMap = useRef<Map<string, string[]>>(new Map<string, string[]>());

  // This value represents the index in the quotes array, it is not the id of the quote
  const [nextQuoteIndex, internalSetNextQuoteIndex] = useState<number>(-1);
  // Little trick using a ref so the nextQuoteIndex state can be updated from a listener callback
  const nextQuoteIndexRef = useRef<number>(nextQuoteIndex);
  const setNextQuoteIndex = (data: number) => {
    nextQuoteIndexRef.current = data;
    internalSetNextQuoteIndex(data);
  };

  // ----- Utilities function -----
  const isScrolledToBottom = () => window.innerHeight + window.pageYOffset >= document.body.offsetHeight - PAGE_DOWN_OFFSET;
  const areAllQuotesLoaded = () => quotes[quotes.length - 1].data !== undefined;
  const ensureColorMapHasAuthor = (author: string) => {
    if (!colorMap.current.has(author)) {
      colorMap.current.set(author, generateColorsFromHash(stringToHash(author)));
    }
  };
  const loadNextQuote = () => {
    // don't read this
    if (nextQuoteIndexRef.current !== -1 && nextQuoteIndexRef.current < quotes.length - 1) {
      setNextQuoteIndex(nextQuoteIndexRef.current + 1);
    }
  };

  useEffect(() => {
    // Try to login if there is a saved token
    let loginTokenJWT = sessionStorage.getItem(LOCAL_STORAGE_LOGIN);
    if (!loginTokenJWT) loginTokenJWT = localStorage.getItem(LOCAL_STORAGE_LOGIN);
    if (loginTokenJWT) {
      const payloadJWT = parseJWTLogin(loginTokenJWT);

      // Check if login is stil valid
      if (getTimestamp() > payloadJWT.exp) {
        localStorage.removeItem(LOCAL_STORAGE_LOGIN);
        sessionStorage.removeItem(LOCAL_STORAGE_LOGIN);
        addNotif('Reconnexion requise', 'Token de connexion expiré, veuillez vous connecter', 'warning');
        
        return;
      }

      setLoginInfo({
        tokenJWT: loginTokenJWT,
        payloadJWT,
        hashedMail: sha256Hash(payloadJWT.usr),
      });
    }

    // Populate quote array with ids
    fetchQuotesId()
      .then(async quoteIds => {
        if (quoteIds === null) return;

        // Reverse array (most recent first)
        quoteIds.reverse();

        setQuotes(quoteIds.map((id): IQuoteInternal => {
          return { id, data: undefined };
        }));

        // The quote of the day must be at least one day old from today midnight
        // The first part of the id of the quote is a timestamp
        // We use this here to find the most recent quote that isn't from today
        // We expect that quoteIds is from most recent to oldest
        const currentDate = new Date();
        currentDate.setHours(0);
        currentDate.setMinutes(0);
        currentDate.setSeconds(0);
        currentDate.setMilliseconds(0);
        const midnightTimestamp = +currentDate;
        let randMin = 0;
        for (let i = 0; i < quoteIds.length; ++i) {
          const quoteTimestamp = parseInt(quoteIds[i].split(':')[0]);
          if (quoteTimestamp <= midnightTimestamp) {
            randMin = i;
            break;
          }
        }

        // Select a random quote for the day (we use YYYY-MM-DD for the random seed)
        const randomGenerator = randSeed.create(`${currentDate.getFullYear()}-${currentDate.getMonth() + 1}-${currentDate.getDate()}`);
        const quoteOfTheDayIndex = randomGenerator.intBetween(randMin, quoteIds.length - 1);
        const quoteOfTheDayId = quoteIds[quoteOfTheDayIndex];
        
        setQuoteOfTheDay(await fetchQuote(quoteOfTheDayId));
      })
      .catch(defaultCatchFetchError);
  }, []);

  useEffect(() => {
    if (quotes.length === 0) return;

    // Load quote until the screen is full of quotes
    if (isScrolledToBottom() && !areAllQuotesLoaded()) {
      loadNextQuote();
    }

    // This if statement can be considered as initialization code,
    // it runs after the ids are fetched.
    if (quotes[0].data === undefined) {
      setNextQuoteIndex(0);

      window.onresize = window.onscroll = () => {
        if (isScrolledToBottom()) loadNextQuote();
      };
    }
  }, [quotes]);

  useEffect(() => {
    if (nextQuoteIndex === -1) {
      // No more quotes or not initialized yet
      return;
    }

    // Fetch next quote
    const quoteId = quotes[nextQuoteIndex].id;
    fetchQuote(quoteId)
      .then(quote => {
        if (quote.data === undefined) return;

        ensureColorMapHasAuthor(quote.data.author);

        setQuotes(quotesData => quotesData.map((otherQuote) => (
          otherQuote.id === quote.id ? quote : otherQuote
        )));
      })
      .catch((error: Error) => { // Silence 404 quote fetch error
        if (!(error instanceof FetchError)) throw error;
        if (error.response.status !== 404) throw error;

        console.error(`[404] Cannot fetch quote with id ${quoteId}`);

        // Delete unfetchable quote from the list of quotes to fetch
        setQuotes(quotesData => quotesData.filter((otherQuote) => otherQuote.id !== quoteId));
      })
      .catch(defaultCatchFetchError);
  }, [nextQuoteIndex]);

  // ----- UI buttons -----
  // Functions called when we click on a button
  // They are named like that: (on...Click)
  const onLoginClick = () => {
    // Assert user is not already logged
    if (loginInfo !== null) {
      addNotif('Impossible de se connecter', `Vous êtes déjà connecté en tant que "${loginInfo.payloadJWT?.usr}"`, 'danger');
      return;
    }

    const login = () => {
      const user: IUser = {
        email: (document.getElementById('email') as HTMLInputElement).value,
        password: (document.getElementById('password') as HTMLInputElement).value,
      };
  
      if (!validateEmail(user.email)) {
        addNotif('Erreur de format', "Format de l'adresse email invalide", 'warning');
        return;
      }
  
      if (user.password === '') {
        addNotif('Erreur de format', 'Mot de passe vide', 'warning');
        return;
      }
  
      loginToDB(user)
        .then(jsonData => {
          const payloadJWT = parseJWTLogin(jsonData.login_token);
          setLoginInfo({
            tokenJWT: jsonData.login_token,
            payloadJWT,
            hashedMail: sha256Hash(payloadJWT.usr),
          });
  
          sessionStorage.setItem(LOCAL_STORAGE_LOGIN, jsonData.login_token);
  
          const rememberMe = (document.getElementById('remember_me') as HTMLInputElement).checked;
          if (rememberMe) {
            localStorage.setItem(LOCAL_STORAGE_LOGIN, jsonData.login_token);
          }
  
          addNotif('Connexion réussie', `Vous êtes connecté en tant que "${payloadJWT.usr}"`, 'success');
  
          setModalIsOpen(false);
        })
        .catch(defaultCatchFetchError)
        .catch((error) => {
          if (!(error instanceof ConnexionError)) throw error;
          addNotif('Erreur de connexion', error.message, 'danger');
        });
    };

    setModalContent(<>
      <h1>Connectez-vous</h1>
      <div className={styles.discrete}>{"Pas de compte ? Il faut qu'un autre utilisateur vous invite"}</div>
      <form onSubmit={(e) => {e.preventDefault();}}>
        <TextInput label='E-mail' id='email' type="email" autofocus={true} />
        <TextInput label='Mot de passe' id='password' type="password" />
        <CheckBox label='Mémoriser le mot de passe' id='remember_me' defaultValue={true}/>
        <Button label='Connexion' onClick={login} isSubmit={true}/>
      </form>
    </>);
    setModalIsOpen(true);
  };
  const onLogoutClick = () => {
    if (loginInfo === null) {
      addNotif('Impossible de se déconnecter', "Vous ne pouvez pas vous déconnecter si vous n'êtes pas connectés", 'danger');
      return;
    }

    setLoginInfo(null);
    localStorage.removeItem(LOCAL_STORAGE_LOGIN);
    sessionStorage.removeItem(LOCAL_STORAGE_LOGIN);
    addNotif('Déconnexion réussie', 'Vous êtes déconnecté', 'success');
  };
  const onInviteClick = () => {
    // Assert user is already logged
    if (loginInfo === null) {
      addNotif("Impossible d'inviter", "Vous devez être connecté pour pouvoir inviter quelqu'un", 'danger');
      return;
    }

    const inviteFriend = () => {
      if (loginInfo === null) {
        addNotif('Action non autorisée', "Vous devez être connecté afin d'inviter quelqu'un d'autre", 'danger');
        return;
      }
  
      const friendEmail = (document.getElementById('friend_email') as HTMLInputElement).value;
      if (!validateEmail(friendEmail)) {
        addNotif('Erreur de format', "Format de l'adresse email invalide", 'warning');
        return;
      }
      
      inviteFriendToDB(loginInfo.tokenJWT, friendEmail)
        .then(() => {
          addNotif('Amis invité', `Votre ami a reçu une invitation à son email ${friendEmail}`, 'success');
          setModalIsOpen(false);
        })
        .catch(defaultCatchFetchError);
    };

    setModalContent(<>
      <h1>Invitez vos amis !</h1>
      <div className={styles.discrete}>Votre ami va recevoir un mail pour confirmer son inscription</div>
      <form onSubmit={(e) => {e.preventDefault();}}>
        <TextInput label='E-mail de votre ami' id='friend_email' autofocus={true} />
        <Button label='Invitez un ami' onClick={inviteFriend} isSubmit={true}/>
      </form>
    </>);
    setModalIsOpen(true);
  };
  const onAddQuoteClick = async () => {
    // Assert user is already logged
    if (loginInfo === null) {
      addNotif("Impossible d'ajouter une citation", 'Vous devez être connecté pour pouvoir créer une citation', 'danger');
      return;
    }

    const createQuote = () => {
      if (loginInfo === null) {
        addNotif('Connexion requise', 'Il faut être connecté pour créer une citation', 'danger');
        return;
      }
  
      // Normaly we shouldn't use the DOM, but this way is simpler
      const textElem = (document.getElementById('text') as HTMLTextAreaElement);
      const authorElem = (document.getElementById('author') as HTMLInputElement);
      const yearElem = (document.getElementById('year') as HTMLInputElement);
      const quote: IQuote = {
        text: textElem.value,
        author: authorElem.value,
        year: yearElem.valueAsNumber,
      };
  
      // Validation
      if (quote.text === '') {
        addNotif('Format incorrect', 'Le texte de la citation est vide', 'warning');
        return;
      }
  
      if (quote.author === '') {
        addNotif('Format incorrect', 'Veuillez entrer un auteur', 'warning');
        return;
      }
  
      if (isNaN(quote.year)) {
        addNotif('Format incorrect', 'Veuillez entrer un numéro pour la date', 'warning');
        return;
      }
  
      // Add quote to DB
      addQuoteToDB(loginInfo.tokenJWT, quote)
        .then(addedQuote => {
          if (addedQuote === null) return;
  
          // Clear fields
          textElem.value = '';
          authorElem.value = '';
          yearElem.value = '';
  
          addNotif('Citation ajoutée', `"${quote.text}", - ${quote.author}, ${quote.year}`, 'success');
  
          setModalIsOpen(false);

          ensureColorMapHasAuthor(quote.author);

          // Add quote on UI
          const addedQuoteInternal: IQuoteInternal = {
            id: addedQuote.id,
            data: {
              text: quote.text,
              author: quote.author,
              year: quote.year,
              hashedMail: sha256Hash(addedQuote.metadata.submitter),
            },
          };
          setQuotes(currentQuotes => [addedQuoteInternal, ...currentQuotes]);
        })
        .catch(defaultCatchFetchError);
    };

    setModalContent(<>
      <h1>Ajoutez une citation</h1>
      <div className={styles.discrete}>Entrez dans le sarcophage</div>
      <form onSubmit={(e) => {e.preventDefault();}}>
        <TextArea rows={3} label='Texte' id='text' disabled={loginInfo === null} autofocus={true} />
        <TextInput label='Auteur' id='author' dataList={KNOWN_AUTHORS} disabled={loginInfo === null} />
        <TextInput defaultValue={(new Date()).getFullYear().toString()} label='Année' type='number' id='year' disabled={loginInfo === null} />
        <Button label='Ajouter une citation' onClick={createQuote} isSubmit={true} disabled={loginInfo === null}/>
      </form>
    </>);
    setModalIsOpen(true);
  };
  const onEditClick = (quote: IQuoteInternal) => {
    if (quote.data === undefined) {
      addNotif('Impossible de modifier une citation', 'La citation à modifier est invalide', 'danger');
      return;
    }

    // Assert user is already logged
    if (loginInfo === null) {
      addNotif('Impossible de modifier une citation', 'Vous devez être connecté pour pouvoir modifier une citation', 'danger');
      return;
    }

    // Assert user can change this citation
    const canEditQuote = () => loginInfo.hashedMail === quote.data?.hashedMail;
    if (!canEditQuote()) {
      addNotif('Impossible de modifier une citation',
        "Vous n'avez pas le droit de modifier cette citation car vous ne l'avez pas crée",
        'danger');
      return;
    }

    const editQuote = (quoteId: string) => {
      if (loginInfo === null) {
        addNotif('Connexion requise', 'Il faut être connecté pour modifier une citation', 'danger');
        return;
      }
  
      // Normaly we shouldn't use the DOM, but this way is simpler
      const textElem = (document.getElementById('text_edit') as HTMLTextAreaElement);
      const authorElem = (document.getElementById('author_edit') as HTMLInputElement);
      const yearElem = (document.getElementById('year_edit') as HTMLInputElement);
      const quoteEdited: IQuote = {
        text: textElem.value,
        author: authorElem.value,
        year: yearElem.valueAsNumber,
      };
  
      // Validation
      if (quoteEdited.text === '') {
        addNotif('Format incorrect', 'Le texte de la citation est vide', 'warning');
        return;
      }
  
      if (quoteEdited.author === '') {
        addNotif('Format incorrect', 'Veuillez entrer un auteur', 'warning');
        return;
      }
  
      if (isNaN(quoteEdited.year)) {
        addNotif('Format incorrect', 'Veuillez entrer un numéro pour la date', 'warning');
        return;
      }
  
      editQuoteDB(loginInfo.tokenJWT, quoteId, quoteEdited)
        .then(editedQuote => {
          if (editedQuote === null) return;
  
          // Clear fields
          textElem.value = '';
          authorElem.value = '';
          yearElem.value = '';
  
          addNotif('Citation modifiée', `"${quoteEdited.text}", - ${quoteEdited.author}, ${quoteEdited.year}`, 'success');

          ensureColorMapHasAuthor(quoteEdited.author);

          // Update quote on UI
          setQuotes(quotesData => quotesData.map((otherQuote) => (
            otherQuote.id === quoteId
              ? ({ id: quoteId, data: {
                text: quoteEdited.text,
                author: quoteEdited.author,
                year: quoteEdited.year,
                hashedMail: otherQuote.data?.hashedMail as string } })
              : otherQuote
          )));

          setModalIsOpen(false);
        })
        .catch(defaultCatchFetchError);
    };
    
    setModalContent(<>
      <h1>Modifier une citation</h1>
      <div className={styles.discrete}>{"Réécrivez l'histoire"}</div>
      <form onSubmit={(e) => {e.preventDefault();}}>
        <TextArea rows={3} label='Texte' id='text_edit' defaultValue={quote.data.text}
        disabled={!canEditQuote()} autofocus={true} />
        <TextInput label='Auteur' id='author_edit' defaultValue={quote.data.author}
        dataList={KNOWN_AUTHORS} disabled={!canEditQuote()} />
        <TextInput label='Année' type='number' defaultValue={quote.data.year.toString()}
        id='year_edit' disabled={!canEditQuote()} />
        <Button label='Modifier une citation' onClick={() => { editQuote(quote.id); }} isSubmit={true} disabled={!canEditQuote()}/>
      </form>
    </>);
    setModalIsOpen(true);
  };
  const onDeleteClick = (quote: IQuoteInternal) => {
    const deleteQuote = (quoteId: string) => {
      if (loginInfo === null) {
        addNotif('Connexion requise', 'Il faut être connecté pour supprimer une citation', 'danger');
        return;
      }
  
      deleteQuoteDB(loginInfo.tokenJWT, quoteId)
        .then(() => {
          // Delete quote from state
          setQuotes(quotesData => quotesData.filter((otherQuote) => otherQuote.id !== quote.id));

          addNotif('Citation supprimée', 'Citation supprimée avec succès', 'success');

          setModalIsOpen(false);
        })
        .catch(defaultCatchFetchError);
    };

    setModalContent(<>
      <h1>Êtes-vous sûr de supprimer cette citation ?</h1>
      <div className={styles.discrete}>{'Cette action est irréversible'}</div>
      <form onSubmit={(e) => {e.preventDefault();}}>
        <Button className={styles.confirm_delete} label='Confirmer la suppression'
        onClick={() => { deleteQuote(quote.id); }} isSubmit={true} autofocus={true}/>
      </form>
    </>);
    setModalIsOpen(true);
  };

  return (
    <div className={styles.app}>
      <Modal
        isOpen={modalIsOpen}
        onAfterOpen={() => {}}
        onRequestClose={() => {setModalIsOpen(false);}}
        overlayClassName={styles.modalOverlay}
        className={styles.modal}
      >
        <div className={styles.modalCloseButton} onClick={() => { setModalIsOpen(false);}}>
          <img alt="close icon" src={closeIcon}/>
        </div>
        {modalContent}
      </Modal>
      <ScrollToTop/>
      <Header email={loginInfo === null ? null : loginInfo.payloadJWT.usr }
      onLoginClick={onLoginClick} onInviteClick={onInviteClick} onAddQuoteClick={onAddQuoteClick} onLogoutClick={onLogoutClick}/>
      <div className={styles.braces_container}>
        <img className={styles.braces} alt="braces top" style={/*Joris*/{ 'scale': '0.91' }} src={braceTop}/>
      </div>
      <QuoteOfTheDay key={`${quoteOfTheDay.data?.text}${quoteOfTheDay.data?.author}`} quote={quoteOfTheDay}></QuoteOfTheDay>
      <div className={styles.braces_container}>
        <img className={styles.braces} alt="braces bottom" src={braceBottom}/>
      </div>
      <div className={styles.quote_container}>
        {quotes.map(quote => {
          if (quote.data === undefined) return null;
          return (
            <Quote key={`${quote.data?.text}${quote.data?.author}`} quote={quote} colorMap={colorMap.current}
              showAdminButtons={quote.data.hashedMail === loginInfo?.hashedMail}
              onEditClick={onEditClick}
              onDeleteClick={onDeleteClick}
            />
          );
        })}
      </div>
      <div className={styles.footer_message}>{quotes.length === 0 || areAllQuotesLoaded()
        ? 'Plus aucune citations. ' + NO_QUOTES_MESSAGES[randomIntBetween(0, NO_QUOTES_MESSAGES.length - 1)]
        : 'Chargement...'}</div>
    </div>
  );
}

export default App;
