import React, { useEffect, useState } from 'react';
import styles from '../scss/Register.module.scss';
import Button from '../components/Button';
import TextInput from '../components/TextInput';
import { addNotif, IRegisterInfo, parseJWTRegister } from '../utilities';
import { confirmRegisterToDB, defaultCatchFetchError } from '../DB';
import { useNavigate } from 'react-router-dom';


function Register() {
  const [registerInfo, setRegisterInfo] = useState<IRegisterInfo | null>(null);
  const navigate = useNavigate();

  useEffect(() => {
    // Get register token (last param of current url)
    const registerTokenJWT = window.location.href.substring(window.location.href.lastIndexOf('/') + 1);

    // Test if valid JWT token
    if (!/^(?:[\w-]*\.){2}[\w-]*$/.test(registerTokenJWT)) {
      // Redirection sur le site
      navigate('/');
      addNotif('Token mal formaté', "Le token passé dans l'URL est incorrect. Peut-être est-ce une erreur de copié-collé ?", 'warning');
      return;
    }

    setRegisterInfo({
      tokenJWT: registerTokenJWT,
      payloadJWT: parseJWTRegister(registerTokenJWT),
    });
  }, []);

  const registerOnClick = async () => {
    if (registerInfo === null) {
      addNotif('Une erreur est survenue', "Impossible de récupérer les informations d'inscription", 'danger');
      return;
    }
    
    const password = (document.getElementById('password') as HTMLInputElement).value;

    await confirmRegisterToDB(registerInfo.tokenJWT, password)
      .catch(defaultCatchFetchError);

    // Redirection sur le site
    navigate('/');
    addNotif('Inscription réussi', 'Vous vous êtes bien inscrit', 'success');
  };

  return (
    <>
      <div style={{ 'textAlign': 'center' }}><a href="/">Retourner vers les citations</a></div>
      <h2 className={styles.title}>
        {registerInfo === null
          ? <div>Bonjour, vous avez été invité au Sarcophage.</div>
          : <><div>Bonjour {registerInfo.payloadJWT.for} !</div>
          <div>Vous avez été invité au Sarcophage par {registerInfo.payloadJWT.per}.</div></>}</h2>
      <div className={styles.form}>
        <div>Entrez le mot de passe de votre compte</div>
        <form onSubmit={(e) => {e.preventDefault();}}>
          <TextInput label='Mot de passe' id='password' type="password" />
          <Button label='Inscription' onClick={registerOnClick} isSubmit={true} />
        </form>
      </div>
    </>
  );
}

export default Register;