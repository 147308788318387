import styles from '../scss/TextArea.module.scss';
import React, { ChangeEventHandler, useRef } from 'react';
import { useEffect } from 'react';

interface TextAreaProps { 
  label: string;
  id: string;
  onChange?: ChangeEventHandler<HTMLTextAreaElement>;
  placeholder?: string;
  rows?: number;
  resizable?: boolean;
  disabled?: boolean;
  autofocus?: boolean;
  defaultValue?: string;
}

function TextArea({ label, id, onChange, placeholder, rows, resizable, disabled = false, autofocus = false, defaultValue } : TextAreaProps) {
  const inputRef = useRef<HTMLTextAreaElement | null>(null);

  useEffect(() => {
    if (defaultValue === undefined || inputRef === null || inputRef.current == null) return;

    inputRef.current.value = defaultValue;
  }, []);

  return (
    <div className={styles.container}>
      <textarea ref={inputRef} autoFocus={autofocus} rows={rows ? rows : 1} id={id} name={`name_${id}`} onChange={onChange} disabled={disabled}
      placeholder={placeholder} className={styles.input} style={{ 'resize': (resizable ? undefined : 'none') }}>
      </textarea>
      <label className={styles.label} htmlFor={`name_${id}`}>{label}</label>
    </div>
  );
}

export default TextArea;
