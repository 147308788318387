import styles from '../scss/Header.module.scss';
import { Md5 } from 'ts-md5';
import React from 'react';

interface HeaderProps {
  email: string | null,
  onLoginClick: () => void,
  onInviteClick: () => void,
  onAddQuoteClick: () => void,
  onLogoutClick: () => void,
}

const fonts = <React.Fragment>
  {[
    'AnotherDanger', 'Avengers', 'ComicSans',
    'DontStarve', 'Dovahkiin', 'Helvetica',
    'HollywoodHills', 'MinecraftEnchant', 'Minecraft',
    'OldLondon', 'Prisma', 'Rockwell',
    'RushTurbo', 'Saddlebag', 'ScaryThings',
    'Skyrim', 'SquidGame', 'StarWars',
    'TheCrowRegular', 'TimesNewRoman', 'Valorant',
    'GenshinImpact', 'Upheavtt',
  ].map(font => {
    return (
      <span key={font}>
      &nbsp;
      <a href="/" className={styles.sarcophage} style={{ 'fontFamily': font }}>Sarcophage</a>
      &nbsp;
      </span>
    );
  })}
</React.Fragment>;

function Header({ email, onLoginClick, onInviteClick, onAddQuoteClick, onLogoutClick } : HeaderProps) {
 
  return (
   <div className={styles.header}>
      <div className={styles.titleScrolling}>
         <div className={styles.textScroll}>
          <p>{fonts}</p>
          <p>{fonts}</p>
         </div>
      </div>
      <div className={styles.items}>
      { email === null
        ? <div className={styles.connexion} onClick={onLoginClick}><div>Connexion</div></div>
        :
        <>
          <div className={styles.addQuote} onClick={onAddQuoteClick}><div>Ajouter une citation</div></div>
          <div className={styles.invite} onClick={onInviteClick}><div>{"Inviter quelqu'un"}</div></div>
          <div className={styles.logout} onClick={onLogoutClick}>
          <div><img className={styles.userIdenticon} title={email} alt='user-identicon' src={`https://gravatar.com/avatar/${Md5.hashStr(email)}?d=identicon`}></img>
            Déconnexion</div>
          </div>
        </>
      }
      </div>
   </div>
  );
}

export default Header;
