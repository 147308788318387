import styles from '../scss/QuoteOfTheDay.module.scss';
import React, { ReactNode, useEffect, useState } from 'react';
import { IQuoteInternal } from '../utilities';

interface QuoteOfTheDayProps {
  quote: IQuoteInternal;
}

function QuoteOfTheDay({ quote } : QuoteOfTheDayProps) {
  const [nextQuoteTime] = useState<Date>(new Date());
  const [remainingTimeNode, setRemainingTimeNode] = useState<ReactNode>('Chargement...');

  useEffect(() => {
    nextQuoteTime.setDate(nextQuoteTime.getDate() + 1);
    nextQuoteTime.setHours(0);
    nextQuoteTime.setMinutes(0);
    nextQuoteTime.setSeconds(0);
    nextQuoteTime.setMilliseconds(0);

    const timer = setInterval(() => {
      // Get date
      const currentTime = new Date();

      // Calculate time before next quote
      const deltaTime = nextQuoteTime.getTime() - currentTime.getTime();
      
      // Compute string
      if (deltaTime < 0) {
        setRemainingTimeNode(<a href="/">Rafraîchir la page</a>);
      } else {
        const remainingTime = new Date(deltaTime);
        setRemainingTimeNode(`${(remainingTime.getHours()).toString().padStart(2, '0')}:`
        + `${(remainingTime.getMinutes()).toString().padStart(2, '0')}:`
        + `${(remainingTime.getSeconds()).toString().padStart(2, '0')}`);
      }
    }, 1000);

    return () => {
      clearInterval(timer);
    };
  }, []);

  return (
    <div className={styles.quote}>
		<div className={styles.label}>Citation du jour :</div>
      <div className={styles.text}>{quote.data === undefined ? 'Chargement de la citation du jour...' : quote.data.text}</div>
      <div className={styles.author_year}>{quote.data === undefined ? '...' : `- ${quote.data.author}, ${quote.data.year}`}</div>
		<div className={styles.nextQuoteTimeLabel}>Prochaine citation dans</div>
		<div className={styles.nextQuoteTime}>{remainingTimeNode}</div>
    </div>
  );
}

export default QuoteOfTheDay;
