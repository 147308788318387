import styles from '../scss/Button.module.scss';
import React, { MouseEventHandler } from 'react';

interface ButtonProps { 
  label: string;
  onClick?: MouseEventHandler<HTMLInputElement>;
  disabled?: boolean;
  isSubmit?: boolean;
  autofocus?: boolean;
  className?: string;
}

function Button({ label, onClick, disabled = false, isSubmit = false, autofocus = false, className = '' } : ButtonProps) {
 
  return (
    <div className={styles.container + ' ' + className}>
      <input autoFocus={autofocus} type={isSubmit ? 'submit' : 'button'} className={styles.button}
      onClick={onClick} disabled={disabled} value={label}/>
    </div>
  );
}

export default Button;
